<template>
  <div class='safetyAlarmBehavior mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name">
        <div>检索条件</div>
      </div>
      <el-form ref="searchForm" :model="keyWord" label-width="80px" inline @submit.native.prevent size="small">
        <el-form-item label="设备标识:" prop="boardId">
          <el-input type="text" class="w180" v-model="keyWord.boardId" placeholder="请输入设备标识" clearable
                    @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="告警描述:" prop="content">
          <el-input type="text" class="w180" v-model="keyWord.content" placeholder="请输入告警描述" clearable
                    @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="告警时间:" prop="time">
          <el-date-picker v-model="keyWord.time" value-format="yyyy-MM-dd" clearable
                          :default-time="['00:00:00', '23:59:59']" @change="getList(1)" type="daterange"
                          range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" class="error" @click="batchDel()"><i
              class="el-icon-delete"></i>批量删除
          </el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%"
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="boardName" label="分析设备"/>
          <el-table-column prop="boardId" label="设备标识" width="200" show-overflow-tooltip/>
          <el-table-column prop="videoChannel" label="视频通道"/>
          <el-table-column prop="alarmContent" label="警报内容" show-overflow-tooltip/>
          <el-table-column prop="alarmCount" label="接警次数" width="80" show-overflow-tooltip/>
          <el-table-column :formatter="formatTime2" label="告警时间" width="150" show-overflow-tooltip/>
          <el-table-column prop="alarmTask" label="警报任务的名称"/>
          <el-table-column prop="taskDescription" label="任务描述" show-overflow-tooltip/>
          <el-table-column  label="截图" width="80" fixed="right">
                          <template slot-scope="scope">
                            <el-image
                              style="width: 30px; height: 30px"
                              :src="scope.row.markImageUrl1"
                              :preview-src-list="toArr(scope.row.markImageUrl1)">
                            </el-image>
                          </template>
            </el-table-column>
          <el-table-column prop="videoUrl" label="视频" width="80" fixed="right" show-overflow-tooltip/>
            <el-table-column label="操作" width="150" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" class="error" @click="openDio('del',scope.row)"
                           ><i class="el-icon-delete"></i>删除
                </el-button>
                <el-button type="text" class="primary"
                            @click="openDio('detailDio',scope.row)">
                  <i class="el-icon-s-order"></i>详情
                </el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
            :current-page="curr"
            :page-size="limits"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100,200]"
            :total="total"
            @size-change="sizeChange"
            @current-change="currChange"
        />
      </div>
    </div>
    <!--查看-->
    <alarm-detail ref="detailDio"></alarm-detail>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import {apiUrl, globalStr} from '@/assets/js/api';
import qs from 'qs'
import {formatTime} from '@/utils/utils';
import alarmDetail from './alarm-detail.vue';

var vm;
export default {
  name: 'safety-alarm-devicebox',
  data() {
    return {
      //筛选数据
      keyWord: {
        boardId: '',
        content: '',
        time: ''
      },
      //字典数据
      dicObj: {
        typeData: [],//告警类型
      },
      //分页
      curr: 1,//当前页
      limits: 10,//每页容量
      total: 0,//数据总数

      tableH: '',//表格高度
      tableActive: [],//表格选中
      tableData: [],

      isAdmin: false,//是否是超级管理员
    }
  },
  components: {
    breadCrumb,
    alarmDetail, //详情
  },
  watch: {
    '$store.state.homeId': function (newVal) {
      if (newVal !== '0') {
        vm.getList()
      }
    }
  },
  async created() {
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if (userInfo) {
      this.isAdmin = userInfo.isAdmin
    }
  },
  async mounted() {
    if (vm.$store.state.homeId != null) vm.getList()
    window.onresize = () => {
      vm.initHeight()
    }
  },
  //方法
  methods: {
    toArr(url){
      let arr = [];
      arr.push(url)
      return arr;
    },
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'del') {
        vm.$confirm('确定删除该选项', '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              let arr = []
              if (item) {
                arr.push(item.id)
              }
              vm.deleteDeviceBoxAlarm(arr)
            })
            .catch(() => {
            })
      }else if(type == 'detailDio'){
        vm.$refs.detailDio.init(item);
      }
    },
    //批量删除
    batchDel() {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for (var i in vm.tableActive) {
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteDeviceBoxAlarm(arr);//删除
      }).catch(() => {
      });
    },
    //删除
    async deleteDeviceBoxAlarm(ids) {
      const res = await apiUrl.deleteDeviceBoxAlarm(ids)
      if (res?.code == 200) {
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //过滤时间
    formatTime2(row, column) {
      return row.alarmTime ? formatTime(row.alarmTime, 'Y-M-D h:m:s') : '-'
    },
    //分页选择
    sizeChange(val) {
      vm.limits = val
      vm.getList(vm.curr, val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList(_curr, _limits) {
      let dataObj = {
        'areaId': vm.$store.state.csType,
        'homeId': vm.$store.state.homeId,
        'startTime': vm.keyWord.time ? vm.keyWord.time[0] : null,
        'endTime': vm.keyWord.time ? vm.keyWord.time[1] : null,
        'content': vm.keyWord.content,
        'boardId': vm.keyWord.boardId,
        'pageNo': _curr ? _curr : vm.curr,
        'pageSize': _limits ? _limits : vm.limits
      }
      const res = await apiUrl.deviceBoxAlarmPage(dataObj)
      if (res?.code == 200) {
        vm.tableData = res.data.list;
        vm.total = parseInt(res.data.totalSize);
        vm.curr = _curr ? _curr : vm.curr;
      } else {
        vm.$message.error(res.message);
        vm.tableData = [];
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 322
    }
  }
}
</script>
<style lang='scss'>
.safetyAlarmBehavior {

}
</style>
